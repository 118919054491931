/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
// require('jquery-cookie-bubble/dist/cookieBubble.min.css');
require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');


$(document).ready(function() {
	$('[data-toggle="popover"]').popover();

	$('.modalidad').on('mouseenter', function(e) {
		var obj = $(this);
		$(this).removeClass('reduce');
		$('.modalidad').each(function(index, item) {
			if (!$(obj).is(item)) {
				$(item).addClass('reduce');
			}
		});
	});

	$('.modalidad').on('mouseleave', function(e) {
		$('.modalidad').removeClass('reduce');
	});

	$('.modalidad').on('click', function(e) {
		var target = $(this).attr('data-target');

		var obj = $(this);
		$(this).addClass('active');
		$(this).removeClass('reduce');
		$('.modalidad').each(function(index, item) {
			if (!$(obj).is(item)) {
				$(item).addClass('reduce-0');
			}
		});

		window.location.href = target;
	});

	$('.mod').on('click', function(e) {
		var target = $(this).attr('data-target');
		window.location.href = target;
	});

	$('.delete-line').on('click', function(e) {
		e.preventDefault();
		var url = $(this).attr('href');
		var obj = $(this);

		$.ajax({
			type: "POST",
			url: url,
			success: function(data) {
				location.reload();
			}
		});
	});

	$('.row-line input, .row-line select').on('change', function(e) {
		var parent = $(this).closest('.row-line');
		var url = $(this).closest('.row-line').attr('data-url');

		var data = {
			name: $(parent).find('input[name="name"]').val(),
			dorsal: $(parent).find('input[name="dorsal"]').val(),
			size_shirt: $(parent).find('select[name="size_shirt"]').val(),
			size_pants: $(parent).find('select[name="size_pants"]').val(),
			qty: $(parent).find('input[name="qty"]').val()
		};

		$.ajax({
			type: "POST",
			url: url,
			data: data,
			success: function(data) {
			}
		});
	});

	$('#selector button').click(function() {
		$(this).addClass('active').siblings().removeClass('active');

		$('.size-chart').hide();
		var target = $(this).attr('data-target');
		$(target).show();
	});

	$('form[name="contact"]').on('submit', function(e){
		if ($('#contact_comments').val() == '') {
			e.preventDefault();
			$('#contact_comments').addClass('error-input');
		}
	});
});